<template>
  <div v-show="!loading" class="container pd100" v-wechat-title="$route.meta.title=detail.meeting_name" :img-set="detail.meeting_cover_square">
    <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <van-image class="swiper-img" :src="item.image" alt="banner" />
      </van-swipe-item>
    </van-swipe>
    <div class="header">
      <div class="header-title">{{ detail.meeting_name }}</div>
      <div class="header-txt"><van-icon name="eye-o" class="mr5" />{{ detail.view_count }}</div>
    </div>
    <a :href="'tel:'+detail.meeting_hotline" style="display:block" class="van-hairline--bottom">
      <van-cell
        :title="detail.meeting_hotline"
        icon="phone-o"
        is-link
      />
    </a>
    
    <van-cell v-if="detail.meeting_qywx_id" title="企业微信咨询" @click="onQywxClick" icon="comment-o" is-link :to="`/namecard?qywx_id=${detail.meeting_qywx_id}`" />
    <van-cell :title="detail.meeting_address" icon="location-o" />
    <van-cell :title="detail.meeting_price" icon="gold-coin-o" />
    <van-cell class="mb10" :title="detail.meeting_start_date + ' - ' + detail.meeting_end_date" icon="clock-o" />
    <van-cell v-if="detail.show_booked" class="mb10" title="报名人数" :value="detail.already_count + ' / ' + detail.ticket_count" />


    <van-cell-group class="mb10">
      <van-cell title="最近参与" />
      <van-row>
        <van-col span="3" v-for="(member,index) in detail.meeting_last_member" :key="index">
          <div style="margin-top:10px;margin-bottom:10px;text-align: center;">
            <van-image
              round
              width="0.7rem"
              height="0.7rem"
              fit="cover"
              :src="member.student_avatar"
            />
            <div style="margin:0 10px;;overflow: hidden;white-space:nowrap;text-overflow: ellipsis;color: #646566;font-size: 10px;text-align: center;">{{member.student_name}}</div>
          </div>
        </van-col>
      </van-row>
    </van-cell-group>
    

    <van-cell title="活动简介" />
    <div class="desc bgf desc-mar">
      <div class="content ql-editor" style="padding-top:10px" v-html="detail.meeting_description" />
    </div>
    <div class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        :disabled="btn_disabled"
        type="primary"
        @click="onSubmit"
      >立即报名</van-button>
    </div>
    <copyright v-show='!loading' />
    <sharefs />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Swipe, SwipeItem } from 'vant'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Copyright from '@/components/Copyright'
import Sharefs from '@/components/sharefs'

export default {
  name: 'EventsDetail',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Copyright,
    Sharefs
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      detail: {},
      meet_id:'',
      ticket_id:'',
      channel_id:'',
      loading:false,
      btn_disabled:false,
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onQywxClick(){
      this.$api.click_index({event_id:this.meet_id,event_type:'event_qywx_click'}).then(res=>{})
    },
    onShare(){
      let that = this
      wx.ready(function () {
        //分享给微信朋友
        wx.onMenuShareAppMessage({
          title: that.detail.meeting_name,
          desc: '',
          link: that.detail.meeting_share_url,
          imgUrl: that.detail.meeting_cover_square,
          success: function success(res) {
            // Toast.success('分享成功')
          },
          cancel: function cancel(res) {
            // console.log('已取消');
          },
          fail: function fail(res) {
            //alert(JSON.stringify(res));
          }
        });
        // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
        wx.onMenuShareTimeline({
          title: that.detail.meeting_name,
          link: that.detail.meeting_share_url,
          imgUrl: that.detail.meeting_cover_square,
          success: function success(res) {
            //alert('已分享');
          },
          cancel: function cancel(res) {
            //alert('已取消');
          },
          fail: function fail(res) {
            //alert(JSON.stringify(res));
          }
        });
      });
    }, 
    onDetail() {
      this.loading = true
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      let meet = this.$route.query
      this.meet_id = meet.meeting_id
      this.ticket_id = meet.ticket_id
      this.channel_id = meet.channel_id
      let obj = { meeting_id: this.meet_id }
      if(this.ticket_id){
        obj.ticket_id = this.ticket_id
      }
      if(this.channel_id){
        obj.channel_id = this.channel_id
      }
      this.$api.events_detail(obj).then(res => {
        this.$toast.clear()
        if(res.data.ticket_count > 0 ? res.data.already_count >= res.data.ticket_count : false){
          this.btn_disabled = true
        }
        if(res.data.status==3){
          this.btn_disabled = true
        }
        this.banner = [{ id: '111', image: res.data.meeting_cover }]
        this.detail = res.data
        this.onShare()
        this.loading = false
      })
    },
    onSubmit() {
      let pathStr = `/events/submit?meeting_id=${this.meet_id}`
      if(this.$route.query._source){
        pathStr = this.$route.query._source?`/events/submit?meeting_id=${this.meet_id}&_source=${this.$route.query._source}`:`/events/submit?meeting_id=${this.meet_id}`
      }
      if(this.ticket_id){
        pathStr = this.$route.query._source?`/events/submit?meeting_id=${this.meet_id}&ticket_id=${this.ticket_id}&_source=${this.$route.query._source}`:`/events/submit?meeting_id=${this.meet_id}&ticket_id=${this.ticket_id}`
      }
      if(this.channel_id){
        pathStr = this.$route.query._source?`/events/submit?meeting_id=${this.meet_id}&channel_id=${this.channel_id}&_source=${this.$route.query._source}`:`/events/submit?meeting_id=${this.meet_id}&channel_id=${this.channel_id}`
      }
      this.$router.push({ path: pathStr })
    }
  },
}
</script>

<style lang="less" scoped>

  .swiper {
    &-img {
        width: 100%;
        height: 180px;
    }
  }
  .header{
    background: #fff;
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15PX;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-title{
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        columns: #333;
    }
    &-txt{
        color: #999;
        font-size: 12px;
        display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .desc-mar{
    margin-bottom: 10px;
  }
</style>

