<template>
    <div>
        <div
            class="all-share"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="showSheet"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
        >
            <img src="@/assets/friend.png" alt="">
        </div>
        <van-popup v-model="showShareImg" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto'}">
            <div class="all-share-img">
                <img :src="share_image_base64">
                <canvas v-show="false" id="my_canvas" ref="my_canvas" width="750" height="1334"></canvas>
                <div v-if="!loading" class="all-share-remain">( 长按上方图片保存并分享 )</div>
                <div v-show="!loading" @click="toCopy" class="all-share-copy">
                    <img src="@/assets/link.png" alt="">
                    <span>复制链接</span>
                </div>
                <loadings :loading='loading' />
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { Popup } from 'vant'
    var QRCode = require('qrcode')
    import Loadings from '@/components/loading'
    export default {
        name:"share",
        data() {
            return {
                moveTop:30,
                moveLeft:320,
                showShareImg:false,
                loading:false,
                share_image_base64:'',
                link_url:'',
                custom:{},
                defualt:{}
            }
        },
        components: {
            [Popup.name]:Popup,
            loadings:Loadings,
        },
        methods: {
            
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            showSheet(){
                this.showShareImg = true
                let appid = localStorage.getItem('appid',)
                let name = `${appid}-Access-Token`
                if(!localStorage.getItem(name)){
                    localStorage.setItem('is_whiteList',1)
                }
                if(this.share_image_base64) return false
                this.loading = true
                this.$api.tools_poster({share_url:location.href,share_type:'meeting',share_type_id:this.$route.query.meeting_id})
                .then(result=>{
                    let that = this// 根据地址生成二维码
                    this.custom = result.data.custom
                    this.defualt = result.data.defualt
                    if(result.data.custom){
                        this.link_url = this.custom.share_url
                        this.$nextTick(()=>{
                            that.generateQR(this.custom.share_url).then(res => {
                                let canvas = this.$refs.my_canvas
                                let ctx = canvas.getContext('2d')
                                let img1 = new Image()
                                let img2 = new Image()
                                // 处理跨域
                                img1.crossOrigin = 'anonymous'
                                img2.crossOrigin = 'anonymous'
                                img1.src = this.custom.share_image // 背景图路经
                                img2.src = res // 生成的二维码base64
                                img1.onload = function () {
                                    ctx.drawImage(img1, 0, 0, 750, 1334) // 背景图载入画板
                                    ctx.drawImage(img2, that.custom.share_params.left*2, that.custom.share_params.top*2, that.custom.share_params.width*2, that.custom.share_params.height*2)
                                    ctx.font="34px Arial"
                                    ctx.fillStyle="#fff"
                                    ctx.fillText(that.custom.share_from,10,1315)
                                    that.share_image_base64 = canvas.toDataURL('image/jpeg', 1)
                                    that.loading = false
                                }
                            })
                        })
                    }else{
                        this.link_url = this.defualt.share_url
                        this.$nextTick(()=>{
                            that.generateQR(this.defualt.share_url).then(res => {
                                let canvas = this.$refs.my_canvas
                                let ctx = canvas.getContext('2d')
                                let img1 = new Image()
                                let img2 = new Image()
                                let img3 = new Image()
                                // 处理跨域
                                img1.crossOrigin = 'anonymous'
                                img2.crossOrigin = 'anonymous'
                                img3.crossOrigin = 'anonymous'
                                img1.src = this.defualt.share_image // 背景图路经
                                img2.src = res // 生成的二维码base64
                                img1.onload = function () {
                                    ctx.drawImage(img1, 0, 0, 750, 1334) // 背景图载入画板
                                    ctx.drawImage(img2, 68, 1032, 187, 187)
                                    ctx.font="34px Arial"
                                    ctx.fillStyle="#333"                                   
                                    ctx.fillText(that.defualt.share_params.meeting_nice_time,80,360)
                                    ctx.fillText(`主办方：${that.defualt.share_params.meeting_organizer}`,80,460)
                                    ctx.fillText(`招募数：${that.defualt.share_params.ticket_count}`,80,560)
                                    let address = []
                                    if(that.defualt.share_params.address.length>16){
                                        address[0] = that.defualt.share_params.address.slice(0,16)
                                        address[1] = that.defualt.share_params.address.slice(16)
                                        ctx.fillText(address[0],80,660)
                                        ctx.fillText(address[1],80,700)
                                    }else{
                                        ctx.fillText(that.defualt.share_params.address,80,660)
                                    }
                                    ctx.font="34px Arial"
                                    ctx.fillStyle="#fff"
                                    ctx.fillText(that.defualt.share_from,10,1315)
                                    ctx.font="40px Arial"
                                    ctx.fillStyle="#000"
                                    let str = that.defualt.share_title
                                    if(that.defualt.share_title.length >12){
                                        str = that.defualt.share_title.slice(0,11)
                                        str = `${str}...`
                                    }
                                    ctx.textAlign = 'center'
                                    ctx.fillText(str,340,260,550)
                                    that.share_image_base64 = canvas.toDataURL('image/jpeg', 1)
                                    that.loading = false
                                    // img3.src = that.defualt.share_from_avatar
                                    // img3.onload = function () {
                                    //     ctx.beginPath()
                                    //     ctx.fillStyle = '#fff'
                                    //     ctx.arc(375,455,70,0,Math.PI*2)
                                    //     ctx.fill()
                                    //     ctx.clip()
                                    //     ctx.drawImage(img3, 305, 385, 140, 140) // 背景图载入画板
                                    //     that.share_image_base64 = canvas.toDataURL('image/jpeg', 1)
                                    //     that.loading = false
                                    // }
                                }
                            })
                        })
                    }
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async generateQR (text) {
                return QRCode.toDataURL(text,{margin: 1,quality: 1})
            },
            toCopy(){
                let that = this
                this.$copyText(that.link_url).then(function (e) {
                    that.$toast.success('复制成功')
                }, function (e) {
                    that.$toast.error('复制出错')
                })
            },
        },
    }
</script>

<style lang="less">
    .all-share{
        position: fixed;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
    .all-share-img{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        img{
            width: 187.5px;
            border-radius: 2px;
        }
    }
    .all-share-remain{
        margin-top: 10px;
    }
    .all-share-copy{
        margin-top:10px;
        color: #cdcdcd;
        display: flex;
        align-items: center;
        img{
            width: 24px;
            margin-right: 10px;
        }
    }
</style>